function MaleIcon() {
  return (
    <svg
      width="175"
      height="175"
      viewBox="0 0 175 175"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="175" height="175" rx="4" fill="#F4F4F4" />
      <path
        d="M79 119.286V90.4857H71V65.8C71 63.5372 71.784 61.6007 73.352 59.9906C74.92 58.3806 76.8027 57.5742 79 57.5714H95C97.2 57.5714 99.084 58.3778 100.652 59.9906C102.22 61.6034 103.003 63.5399 103 65.8V90.4857H95V119.286H79ZM87 53.4572C84.8 53.4572 82.9173 52.6521 81.352 51.0421C79.7867 49.432 79.0027 47.4942 79 45.2286C78.9973 42.963 79.7813 41.0265 81.352 39.4192C82.9227 37.8119 84.8053 37.0055 87 37C89.1947 36.9945 91.0787 37.8009 92.652 39.4192C94.2253 41.0375 95.008 42.974 95 45.2286C94.992 47.4832 94.2093 49.4211 92.652 51.0421C91.0947 52.6631 89.2107 53.4681 87 53.4572Z"
        fill="#202020"
      />
      <path
        d="M85.2336 133.157V135.837H86.9699V137.094H85.2336V140.116H83.716V133.157H85.2336ZM85.2336 140.717V145.339H77.2355V140.717H85.2336ZM78.7258 141.907V144.135H83.7434V141.907H78.7258ZM77.55 133.758V138.106C79.1359 138.092 80.8039 137.949 82.6086 137.6L82.7863 138.844C80.7355 139.255 78.8352 139.364 77.0441 139.364H76.0188V133.758H77.55ZM91.7605 134.921C91.7469 136.602 92.7312 138.133 94.6727 138.749L93.8523 139.938C92.5057 139.466 91.5623 138.571 91.0359 137.395C90.4891 138.742 89.4568 139.801 87.9598 140.321L87.1531 139.09C89.2039 138.421 90.2293 136.766 90.2293 134.989V133.813H91.7605V134.921ZM97.5848 133.143V140.417H96.0672V136.78H93.6062V135.536H96.0672V133.143H97.5848ZM93.4012 140.745C96.0262 140.745 97.6258 141.62 97.6258 143.124C97.6258 144.628 96.0262 145.489 93.4012 145.489C90.7898 145.489 89.1902 144.628 89.2039 143.124C89.1902 141.62 90.7898 140.745 93.4012 140.745ZM93.4012 141.921C91.6785 141.921 90.7078 142.344 90.7078 143.124C90.7078 143.876 91.6785 144.299 93.4012 144.299C95.1238 144.299 96.1082 143.876 96.1082 143.124C96.1082 142.344 95.1238 141.921 93.4012 141.921Z"
        fill="#202020"
      />
    </svg>
  );
}
function FemaleIcon() {
  return (
    <svg
      width="175"
      height="175"
      viewBox="0 0 175 175"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="175" height="175" rx="4" fill="#F4F4F4" />
      <path
        d="M79 119.29V94.603H67L79.2 62.9214C79.8667 61.2756 80.9 59.9726 82.3 59.0126C83.7 58.0525 85.2667 57.5725 87 57.5725C88.7333 57.5725 90.3 58.0525 91.7 59.0126C93.1 59.9726 94.1333 61.2756 94.8 62.9214L107 94.603H95V119.29H79ZM87 53.458C84.8 53.458 82.9173 52.653 81.352 51.0428C79.7867 49.4327 79.0027 47.4947 79 45.229C78.9973 42.9633 79.7813 41.0268 81.352 39.4194C82.9227 37.812 84.8053 37.0055 87 37C89.1947 36.9945 91.0787 37.801 92.652 39.4194C94.2253 41.0377 95.008 42.9743 95 45.229C94.992 47.4838 94.2093 49.4217 92.652 51.0428C91.0947 52.6639 89.2107 53.469 87 53.458Z"
        fill="#202020"
      />
      <path
        d="M78.8625 133.76C79.9836 133.76 80.9064 134.382 81.4602 135.469H84.3449V132.857H85.8488V145.23H84.3449V140.555H81.5695C81.0363 141.785 80.0588 142.496 78.8625 142.496C77.0031 142.496 75.6906 140.814 75.6906 138.121C75.6906 135.455 77.0031 133.76 78.8625 133.76ZM78.8625 135.127C77.8371 135.127 77.1398 136.234 77.1535 138.121C77.1398 140.021 77.8371 141.129 78.8625 141.129C79.8879 141.129 80.5715 140.021 80.5852 138.121C80.5715 136.234 79.8879 135.127 78.8625 135.127ZM81.884 136.713C81.9729 137.144 82.0207 137.615 82.0207 138.121C82.0207 138.552 81.9865 138.948 81.925 139.324H84.3449V136.713H81.884ZM91.7605 134.635C91.7469 136.316 92.7312 137.848 94.6727 138.463L93.8523 139.652C92.5057 139.181 91.5623 138.285 91.0359 137.109C90.4891 138.456 89.4568 139.516 87.9598 140.035L87.1531 138.805C89.2039 138.135 90.2293 136.48 90.2293 134.703V133.527H91.7605V134.635ZM97.5848 132.857V140.131H96.0672V136.494H93.6062V135.25H96.0672V132.857H97.5848ZM93.4012 140.459C96.0262 140.459 97.6258 141.334 97.6258 142.838C97.6258 144.342 96.0262 145.203 93.4012 145.203C90.7898 145.203 89.1902 144.342 89.2039 142.838C89.1902 141.334 90.7898 140.459 93.4012 140.459ZM93.4012 141.635C91.6785 141.635 90.7078 142.059 90.7078 142.838C90.7078 143.59 91.6785 144.014 93.4012 144.014C95.1238 144.014 96.1082 143.59 96.1082 142.838C96.1082 142.059 95.1238 141.635 93.4012 141.635Z"
        fill="#202020"
      />
    </svg>
  );
}

export { MaleIcon, FemaleIcon };
