function ProfileIconDefault() {
  return (
    <>
      <path
        d="M54 54C59.5228 54 64 49.5228 64 44C64 38.4772 59.5228 34 54 34C48.4772 34 44 38.4772 44 44C44 49.5228 48.4772 54 54 54Z"
        fill="#909090"
      />
      <path
        d="M42 56H66C72.624 56 78 61.376 78 68C78 71.312 75.312 74 72 74H36C32.688 74 30 71.312 30 68C30 61.376 35.376 56 42 56Z"
        fill="#909090"
      />
    </>
  );
}

export default ProfileIconDefault;
