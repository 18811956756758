function ProfileIconSelect({ onClick }: Readonly<{ onClick: () => void }>) {
  return (
    <>
      <circle
        cx="96"
        cy="90"
        r="17.25"
        fill="#58D7A2"
        stroke="#D4D4D4"
        strokeWidth="1.5"
        onClick={onClick}
        style={{ cursor: 'pointer' }}
      />
      <path
        d="M102.5 86.5C103.325 86.5 104 87.175 104 88V95C104 95.825 103.325 96.5 102.5 96.5H89.5C88.675 96.5 88 95.825 88 95V88C88 87.175 88.675 86.5 89.5 86.5H102.5ZM102.5 85.5H89.5C88.12 85.5 87 86.62 87 88V95C87 96.38 88.12 97.5 89.5 97.5H102.5C103.88 97.5 105 96.38 105 95V88C105 86.62 103.88 85.5 102.5 85.5Z"
        fill="white"
      />
      <path
        d="M97.4062 83.4375C98.1797 83.4375 98.8125 84.0703 98.8125 84.8438V85.3125H93.1875V84.8438C93.1875 84.0703 93.8203 83.4375 94.5938 83.4375H97.4062ZM97.4062 82.5H94.5938C93.3 82.5 92.25 83.55 92.25 84.8438V86.25H99.75V84.8438C99.75 83.55 98.7 82.5 97.4062 82.5Z"
        fill="white"
      />
      <path
        d="M96 89.5C97.105 89.5 98 90.395 98 91.5C98 92.605 97.105 93.5 96 93.5C94.895 93.5 94 92.605 94 91.5C94 90.395 94.895 89.5 96 89.5ZM96 88.5C94.345 88.5 93 89.845 93 91.5C93 93.155 94.345 94.5 96 94.5C97.655 94.5 99 93.155 99 91.5C99 89.845 97.655 88.5 96 88.5Z"
        fill="white"
      />
    </>
  );
}

export default ProfileIconSelect;
